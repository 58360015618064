import { FC, useRef, useState, useEffect, useMemo } from 'react'
import get from 'lodash/get'
import isNil from 'lodash/isNil'
import { MediaPermissionsErrorType, requestMediaPermissions } from 'mic-check'
import { DotLottieReact } from '@lottiefiles/dotlottie-react'

import {
  applyResponse,
  checkInterrupt,
  dbSendUserEmail,
  dbSkipEmail,
  generateResponse,
  sendAvatarLatency
} from 'controllers/main'
import HeyGenConnect, {
  IHeyGenConnect
} from 'shared/components/HeyGenConnectLiveKit'
import AzureConnect from 'shared/components/AzureConnect'
import CustomAvatarConnect from 'shared/components/CustomAvatarConnect'
import Recorder, { IRecorder } from 'shared/components/Recorder'
import RecorderDummy from 'shared/components/RecorderDummy'
import PageContainer from 'shared/components/PageContainer'
import { ControlT } from 'types/internal'
import { PermissionErrorType } from 'types/proto'
import { TwoFactorT } from 'types/twoFactor'
import EndConversation from 'shared/components/EndConversation'
import ControlPanel, {
  IControlPanel,
  Step
} from 'shared/components/ControlPanel'
import FixPermission from 'shared/components/FixPermission'
import PermissionsHandler from 'shared/components/PermissionsHandler'
import PACKAGE from '../../package.json'
import trim from 'lodash/trim'
import map from 'lodash/map'
import compact from 'lodash/compact'
import filter from 'lodash/filter'
import jsLogger from 'js-logger'
import { getBackClient } from 'controllers/back'
import { DBT } from 'types/internal'
import { getString } from 'controllers/localization'
import DIDConnect from 'shared/components/DIDConnect'
import CapturePhoto from 'pages/converstion/CapturePhoto'
import EmailInput from 'pages/converstion/EmailInput'

const SILENCE_DURATION = 10000

type Props = {
  interactionId: string
  onConversationFinished: () => void
  handleChunk: (
    videoBlob: Blob,
    mimeType: string,
    role: 'user' | 'avatar'
  ) => void
  initVersion: 0 | 1
  isTest: boolean
  setDuration: (v: number) => void
  azureKey?: { token: string; region: string }
  heygenKey?: string
  steps: TwoFactorT.StepT[]
  setSteps: (steps: TwoFactorT.StepT[]) => void
  setRecapData: (data: TwoFactorT.RecapT | null) => void
  avatar: TwoFactorT.AvatarT
  interruptionMode: DBT.INTERRUPT
  lang: string
  availableLanguages: DBT.LangT[]
  onChangeLang: (lang: string) => void
  transcriber: DBT.TRANSCRIBER
  transcriberKey: string
  avatarId?: string
  avatarQuality?: string
}

const Conversation: FC<Props> = ({
  interactionId,
  onConversationFinished,
  handleChunk,
  initVersion,
  isTest,
  setDuration,
  azureKey,
  heygenKey,
  steps,
  setSteps,
  setRecapData,
  avatar,
  interruptionMode,
  lang,
  availableLanguages,
  onChangeLang,
  transcriber,
  transcriberKey,
  avatarId,
  avatarQuality
}) => {
  const startTimeRef = useRef<number>(Date.now())
  const recorderRef = useRef<IRecorder>(null)
  const heyGenRef = useRef<IHeyGenConnect>(null)
  const cameraVideoRef = useRef<HTMLVideoElement>(null)
  const isOverRef = useRef(false)
  const [orientation, setOrientation] = useState<'portrait' | 'landscape' | ''>(
    ''
  )
  const [controls, setControls] = useState<ControlT>({
    camera: isTest,
    mic: isTest
  })
  const controlsRef = useRef<ControlT>({ camera: false, mic: false })
  const [thereWasAnError, setThereWasAnError] = useState<string>()
  const [permissionsError, setPermissionsError] =
    useState<PermissionErrorType | null>(null)
  const streamRef = useRef<MediaStream | null>(null)
  const [closed, setClosed] = useState<boolean>(false)
  const [permissionsChecked, setPermissionsChecked] = useState<boolean>(false)
  const controlPanelRef = useRef<IControlPanel>(null)
  const [userName, setUserName] = useState<string | null>(null)
  const [checkingPermissions, setCheckingPermissions] = useState(false)
  const [controlPanelVisible, setControlPanelVisible] = useState(
    initVersion === 1
  )
  const [selectedDeviceId, setSelectedDeviceId] = useState<string | null>(null)
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([])
  const avatarIsTalkingRef = useRef(false)
  const userIsTalkingRef = useRef(false)
  const nextQuestionRef = useRef<TwoFactorT.QuestionT | null>(null)
  const userSpeechBufferRef = useRef('')
  const lastAppliedPhraseRef = useRef<TwoFactorT.QuestionT>()
  const [permisssionsGranted, setPermissionsGranted] = useState(false)
  const silenceTimerRef = useRef<number>(0)
  // const facedetectorRef = useRef<mediapipe.FaceDetector | null>(null)
  const [screenshotProcessed, _setScreenshotProcessed] = useState(false)
  const screenshotProcessedRef = useRef(false)
  const [cameraOn, setCameraOn] = useState(false)
  const [avatarStarted, setAvatarStarted] = useState(false)
  const [showEmailInput, _setShowEmailInput] = useState(false)
  const showEmailInputRef = useRef(false)
  const [subtitlesOpenedByDefault, setSubtitlesOpenedByDefault] =
    useState(false)
  const replyGenerationRequestTimestampRef = useRef(0)

  jsLogger.log('CONVERSATION LANG:', lang)

  const processUrlParams = () => {
    const url = new URL(window.location.href)
    const cc = url.searchParams.get('cc')
    if (cc !== null) {
      setSubtitlesOpenedByDefault(true)
    }
  }

  useEffect(() => {
    jsLogger.info('CONVERSATION START')

    processUrlParams()

    // @ts-ignore
    if (window.fireTestEvent) {
      // @ts-ignore
      window.fireTestEvent('ev_conversation_started', {
        interactionId,
        version: PACKAGE.version
      })
    }
    function handleOrientationChange (event: { matches: any; media: any }) {
      const { matches } = event
      setOrientation(matches ? 'portrait' : 'landscape')
    }

    const mediaQueryPortrait = window.matchMedia('(orientation: portrait)')
    setOrientation(mediaQueryPortrait.matches ? 'portrait' : 'landscape')

    mediaQueryPortrait.addEventListener('change', handleOrientationChange)

    return () => {
      mediaQueryPortrait.removeEventListener('change', handleOrientationChange)
      if (silenceTimerRef.current) {
        clearTimeout(silenceTimerRef.current)
      }
      const back = getBackClient()
      back.disconnect()
    }
  }, [])

  const initialPhrase = useMemo(() => {
    const q = get(steps, 0)
    if (q.sType === TwoFactorT.StepTypeT.QUESTION) {
      return q.text
    }
  }, [steps])

  const setScreenshotProcessed = (v: boolean) => {
    _setScreenshotProcessed(true)
    screenshotProcessedRef.current = true
  }

  const setShowEmailInput = (v: boolean) => {
    _setShowEmailInput(v)
    showEmailInputRef.current = v
  }

  // useEffect(() => {
  //   const run = async () => {
  //     try {
  //       jsLogger.log('loading model start')
  //       const startTime = Date.now()
  //       const vision = await mediapipe.FilesetResolver.forVisionTasks(
  //         'https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@latest/wasm'
  //       )
  //       facedetectorRef.current =
  //         await mediapipe.FaceDetector.createFromOptions(vision, {
  //           baseOptions: {
  //             modelAssetPath: '/models/blaze_face_short_range.tflite'
  //           },
  //           runningMode: 'IMAGE'
  //         })
  //       jsLogger.log('loading model end', { latency: Date.now() - startTime })
  //     } catch (e) {
  //       jsLogger.error('model loading error', { e })
  //     }
  //   }
  //   run()
  // }, [])

  useEffect(() => {
    if (permissionsChecked) {
      startTimeRef.current = Date.now()
    }
  }, [permissionsChecked])

  // useEffect(() => {
  //   jsLogger.log('useEffect permissionsChecked call')
  //   const run = async () => {
  //     if (permissionsChecked && !isTest) {
  //       jsLogger.log('useEffect permissionsChecked, start screnshots timer', {
  //         permissionsChecked,
  //         isTest
  //       })
  //     } else if (isTest) {
  //       setTimeout(() => {
  //         applyTestScreenshots(interactionId)
  //       }, 6000)
  //     }
  //   }
  //   run()
  // }, [permissionsChecked])

  useEffect(() => {
    const stream = streamRef.current as MediaStream
    if (stream) {
      const audioTrack = stream
        .getTracks()
        .find(track => track.kind === 'audio')
      if (audioTrack) {
        audioTrack.enabled = controls.mic
      }
      const videoTrack = stream
        .getTracks()
        .find(track => track.kind === 'video')
      if (videoTrack) {
        videoTrack.enabled = controls.camera
      }
    }
  }, [controls])

  const controlPanelSteps = useMemo(() => {
    return compact(
      map(steps, s => {
        if (
          s.sType === TwoFactorT.StepTypeT.QUESTION ||
          s.sType === TwoFactorT.StepTypeT.REPLY
        ) {
          return {
            id: s.id,
            text: s.text,
            isAvatar: s.sType === TwoFactorT.StepTypeT.QUESTION
          } as Step
        } else {
          return null
        }
      })
    )
  }, [steps])

  const onHeyGenSessionStarted = () => {
    jsLogger.debug('HeyGenSessionStarted')
    jsLogger.log('camera and mic are enabled, saying initial phrase!!!!!!!')
    const heygenLatency = Date.now() - startTimeRef.current
    // @ts-ignore
    if (window.fireTestEvent) {
      // @ts-ignore
      window.fireTestEvent('ev_heygen_started', {
        latency: heygenLatency,
        interactionId,
        version: PACKAGE.version
      })
    }
    setAvatarStarted(true)
    // const q = get(steps, 0)
    // if (q.sType === TwoFactorT.StepTypeT.QUESTION) {
    //   lastAppliedPhraseRef.current = q
    //   setTimeout(() => heyGenRef.current?.say(q.text), 1000)
    // }
  }

  const onCustomAvatarSessionStarted = () => {
    jsLogger.debug('CustomAvatarSessionStarted')
    jsLogger.log('Avatar is ready, saying initial phrase!!!!!!!')
    const avatarLatency = Date.now() - startTimeRef.current

    // Custom event logging
    if (window.fireTestEvent) {
      window.fireTestEvent('ev_custom_avatar_started', {
        latency: avatarLatency,
        interactionId,
        version: PACKAGE.version
      })
    }

    const q = get(steps, 0)
    if (q.sType === TwoFactorT.StepTypeT.QUESTION) {
      lastAppliedPhraseRef.current = q
      setTimeout(() => heyGenRef.current?.say(q.text), 2000)
    }
  }

  const onAvatarStartTalking = (latency: number) => {
    if (lastAppliedPhraseRef.current) {
      sendAvatarLatency(interactionId, lastAppliedPhraseRef.current.id, latency)
    }
  }

  const checkMediaPermissions = async () => {
    if (isTest) {
      setPermissionsError(null)
      setPermissionsChecked(true)
      setControlPanelVisible(true)
      return
    }
    try {
      jsLogger.debug('permissions_requested')
      setCheckingPermissions(true)
      await requestMediaPermissions()
      jsLogger.debug('permissions_granted')
      setCheckingPermissions(false)
      jsLogger.debug('no error with permissions')
      setPermissionsError(null)
      setPermissionsChecked(true)
      setControlPanelVisible(true)
      heyGenRef.current?.play()
      controlsRef.current = { mic: true, camera: true }
      setControls({ mic: true, camera: true })
    } catch (error: any) {
      jsLogger.error('MediaOnboardingDialog: ', error)
      if (error.type === MediaPermissionsErrorType.Generic) {
        setPermissionsError(PermissionErrorType.systemDenied)
      } else if (
        error.type === MediaPermissionsErrorType.SystemPermissionDenied
      ) {
        // user denied permission
        setPermissionsError(PermissionErrorType.systemDenied)
      } else if (
        error.type === MediaPermissionsErrorType.UserPermissionDenied
      ) {
        // browser doesn't have access to devices
        setPermissionsError(PermissionErrorType.userDenied)
      } else if (
        error.type === MediaPermissionsErrorType.CouldNotStartVideoSource
      ) {
        // most likely when other apps or tabs are using the cam/mic (mostly windows)
        setPermissionsError(PermissionErrorType.trackError)
      }
    }
  }

  const onAvatarPlayingFinished = () => {
    jsLogger.log('%conAvatarPlayingFinished', { isOver: isOverRef.current })
    avatarIsTalkingRef.current = false
    // @ts-ignore
    if (window.fireTestEvent) {
      // @ts-ignore
      window.fireTestEvent('ev_avatar_ended_talking', {
        isOver: isOverRef.current,
        interactionId,
        version: PACKAGE.version
      })
    }
    setupSilenceTimer()
    if (nextQuestionRef.current) {
      const q = nextQuestionRef.current
      jsLogger.log('found saved response', { q })
      sendApplyResponse(q)
      nextQuestionRef.current = null
      heyGenRef.current?.say(q.text)
      avatarIsTalkingRef.current = true
      // setIsRecording(true)
      // recorderRef.current?.start()
    } else if (isOverRef.current) {
      recorderRef.current?.stop()
      removeSilenceTimer()
      setTimeout(onConversationFinished, azureKey ? 2000 : 0)
    } else if (!userIsTalkingRef.current) {
      console.debug('avatar ended speaking and the user is not speaking')
      onUserPhrase('', 0)
    }
  }

  const sendApplyResponse = async (q: TwoFactorT.QuestionT) => {
    console.log('sendApplyResponse start', { q })
    const r = await applyResponse(interactionId, q)
    lastAppliedPhraseRef.current = q
    console.log('sendApplyResponse end', { q, steps: r && r.steps })
    if (r && r.steps) {
      setSteps(r.steps)
    }
  }

  const addToBuffer = (t: string) => {
    const ar = [userSpeechBufferRef.current, t]
    const filteredAr = filter(ar, (v: string) => trim(v) !== '')
    userSpeechBufferRef.current = filteredAr.join(' ')
  }

  const onUserPhrase = async (
    speech: string,
    dgLatency: number,
    forceSend = false
  ) => {
    userIsTalkingRef.current = false
    jsLogger.log('onUserPhrase', {
      speech,
      avatarIsTalking: avatarIsTalkingRef.current,
      forceSend
    })

    const speechWithBuffer =
      userSpeechBufferRef.current !== ''
        ? `${userSpeechBufferRef.current} ${speech}`
        : speech

    // @ts-ignore
    if (window.fireTestEvent) {
      // @ts-ignore
      window.fireTestEvent('ev_on_phrase', {
        latency: dgLatency
      })
    }
    if (showEmailInputRef.current) {
      jsLogger.log('email input is visible now. skipping the speech', {
        speech,
        buffer: userSpeechBufferRef.current,
        forceSend
      })
      // addToBuffer(speech)
      return
    } else if (!screenshotProcessedRef.current && !forceSend) {
      jsLogger.log(
        'screenhost is not precessed yet, saving the speech to the buffer',
        { speech, buffer: userSpeechBufferRef.current, forceSend }
      )
      addToBuffer(speech)
      return
    } else if (
      avatarIsTalkingRef.current &&
      interruptionMode === DBT.INTERRUPT.SMART
    ) {
      const needToInterrupt = await checkInterrupt(
        interactionId,
        speechWithBuffer
      )
      jsLogger.log('need to interrupt', { needToInterrupt })
      if (!needToInterrupt) {
        jsLogger.log(
          'avatar is currently talking: do not need to interrupt, saving the speech to the buffer'
        )
        addToBuffer(speech)
        return
      } else {
        jsLogger.log('avatar is currently talking: need to interrupt')
        heyGenRef.current?.cancel()
      }
    } else if (avatarIsTalkingRef.current) {
      jsLogger.log(
        'avatar is currently talking: do not need to interrupt, saving the speech to the buffer'
      )
      addToBuffer(speech)
      return
    }

    jsLogger.log('onUserPhrase', { buffer: userSpeechBufferRef.current })
    jsLogger.log('speechWithBuffer:', { speech: speechWithBuffer })
    try {
      if (trim(speechWithBuffer) === '' && !forceSend) {
        jsLogger.log(
          'ignore the user phrase because speechWithBuffer is empty',
          {
            speechWithBuffer,
            interactionId,
            forceSend
          }
        )
        return null
      }
      userSpeechBufferRef.current = ''
      jsLogger.debug("processing user's response", {
        userResponse: speechWithBuffer
      })
      const grStartTime = Date.now()
      replyGenerationRequestTimestampRef.current = grStartTime
      const res = await generateResponse(interactionId, speechWithBuffer)
      const grLatency = Date.now() - grStartTime
      jsLogger.log('generateResponse res:', { res, latency: grLatency })
      const q = get(res, 'q') as TwoFactorT.QuestionT
      jsLogger.log('next question is', { text: q.text })
      const over = get(res, 'isOver', false)
      const recapData = get(
        res,
        'interaction',
        null
      ) as TwoFactorT.RecapT | null
      const flags = get(q, 'flags', [])
      jsLogger.log('flags', { flags })

      const showEmailInputDetected = flags.some(
        (f: { key: string; value: boolean }) =>
          f.key === 'emailInputHasBeenShown'
      )

      jsLogger.log('res is over', { over, showEmailInputDetected })

      if (replyGenerationRequestTimestampRef.current !== grStartTime) {
        jsLogger.log('the request is deprecated', {
          requestTimestamp: grStartTime,
          lastRequestTimestamp: replyGenerationRequestTimestampRef.current
        })
        return
      }

      if (res && q) {
        setRecapData(recapData)
        // setSteps([
        //   ...stepsRef.current,
        //   generateStep(res.phrase, InteractionT.StepTypeT.QUESTION)
        // ])
        // currentQuestionId.current = res.questionId
        res.steps && setSteps(res.steps)
        if (isOverRef.current) {
          jsLogger.log(
            'skipping the avatar reply, because the conversation is already over'
          )
        } else if (avatarIsTalkingRef.current && !isOverRef.current && over) {
          jsLogger.log(
            'isOver flag received, saving the avatar phrase to the buffer'
          )
          nextQuestionRef.current = q
          if (interruptionMode !== DBT.INTERRUPT.DISABLED) {
            heyGenRef.current?.cancel()
          }
        } else if (userIsTalkingRef.current && !over) {
          jsLogger.log(
            'user is currently talking, skiping the avatar phrase and wait for a new one'
          )
        } else if (trim(q.text) === '') {
          jsLogger.error('WARNING: backend returned an empty phrase')
        } else if (avatarIsTalkingRef.current) {
          jsLogger.log('avatar is talking, skipping the phrase')
        } else {
          // jsLogger.debug('avatar is NOT talking, switching to talking')
          jsLogger.debug('avatar reply generated:', {
            reply: get(res, 'q.text'),
            isOver: get(res, 'q.isOver'),
            fullGenerationLatency: grLatency,
            ...get(res, 'q.backendLatencies', {})
          })
          sendApplyResponse(q)
          nextQuestionRef.current = null
          heyGenRef.current?.say(q.text)
          avatarIsTalkingRef.current = true
          if (showEmailInputDetected) {
            setShowEmailInput(true)
          }
        }

        if (res.userName && isNil(userName)) {
          setUserName(res.userName)
        }
        if (over) {
          isOverRef.current = over
        }
      }
    } catch (e) {
      jsLogger.error(e)
    }
  }

  const generateNewPhrase = (userReply = '') => {
    onUserPhrase(userReply, 0, true)
  }

  const handleControlsChange = (v: ControlT) => {
    jsLogger.debug('handleControlsChange', v)
    if (!permissionsChecked) {
      checkMediaPermissions()
    }
    controlsRef.current = v
    setControls(v)
  }

  const onSilenceTimer = () => {
    jsLogger.log('onSilenceTimer')
    if (
      avatarIsTalkingRef.current ||
      showEmailInputRef.current ||
      !screenshotProcessedRef.current
    ) {
      jsLogger.log(
        'onSilenceTimer, avatar is talking, or email input is visible planing again',
        {
          avatarIsTalking: avatarIsTalkingRef.current,
          emailInputVisible: showEmailInputRef.current,
          screenshotProcessed: screenshotProcessedRef.current
        }
      )
      setupSilenceTimer()
    } else {
      jsLogger.log(
        'onSilenceTimer, avatar is not talking, need to generate avatar phrase'
      )
      generateNewPhrase()
    }
  }

  const removeSilenceTimer = () => {
    jsLogger.log('remove silence timer')
    if (silenceTimerRef.current) {
      clearTimeout(silenceTimerRef.current)
    }
  }

  const setupSilenceTimer = () => {
    if (avatar === 'd-id') {
      jsLogger.log('silence timer is disabled for D-ID')
      return
    }
    jsLogger.log('setupSilenceTimer --->')
    removeSilenceTimer()
    silenceTimerRef.current = window.setTimeout(
      onSilenceTimer,
      SILENCE_DURATION
    )
  }

  const onSpeech = async (v: string) => {
    jsLogger.log('Conversation onSpeech', {
      speech: v,
      buffer: userSpeechBufferRef.current,
      avatarIsTalking: avatarIsTalkingRef.current,
      isOver: isOverRef.current
    })
    // FIXME: pass current transcript to controlPanel
    controlPanelRef.current?.setSpeech(`${userSpeechBufferRef.current} ${v}`)
    setupSilenceTimer()
    if (avatarIsTalkingRef.current && !isOverRef.current) {
      if (interruptionMode === DBT.INTERRUPT.REGULAR) {
        heyGenRef.current?.cancel()
      } else if (interruptionMode === DBT.INTERRUPT.SMART) {
        const speechWithBuffer =
          userSpeechBufferRef.current !== ''
            ? `${userSpeechBufferRef.current} ${v}`
            : v
        const needToInterrupt = await checkInterrupt(
          interactionId,
          speechWithBuffer
        )
        if (needToInterrupt) {
          heyGenRef.current?.cancel()
        }
      }
    }
  }

  const onCameraOn = () => {
    jsLogger.log('onCameraOn')
    setCameraOn(true)
  }

  const handleClose = () => {
    removeSilenceTimer()
    setClosed(true)
    const back = getBackClient()
    back.disconnect()
  }

  const handleVideoClick = () => {
    if (orientation === 'portrait' && !permissionsError && permissionsChecked) {
      controlPanelRef?.current?.click()
    }
  }

  const handleCameraChange = (v: string | null) => {
    setSelectedDeviceId(v)
    recorderRef.current?.cameraChange(v)
  }

  if (closed) {
    return <EndConversation />
  }

  if (permissionsError) {
    return <PermissionsHandler permissionsError={permissionsError} />
  }

  const avatarEnabled = true

  const renderButton = () => {
    // const title = permisssionsGranted
    //   ? getString('conversation_allow', lang)
    //   : 'Request permissions'
    const title = permisssionsGranted ? 'Start' : 'Request permissions'
    const onClick = permisssionsGranted
      ? () => handleControlsChange({ mic: true, camera: true })
      : requestPermissionsInternally
    return (
      <button
        className='h-16 bg-blue-400 text-white font-medium text-[16px] w-[260px] mt-7 rounded-[4px]'
        onClick={() => onClick()}
      >
        {title}
      </button>
    )
  }

  const requestPermissionsInternally = async () => {
    jsLogger.log('requestPermissionsInternally')
    try {
      jsLogger.debug('permissions_requested internally')
      await requestMediaPermissions()
      jsLogger.debug('permissions_granted internally')
      setPermissionsError(null)
      setPermissionsGranted(true)
    } catch (error: any) {
      jsLogger.error('MediaOnboardingDialog: ', error)
      if (error.type === MediaPermissionsErrorType.Generic) {
        setPermissionsError(PermissionErrorType.systemDenied)
      } else if (
        error.type === MediaPermissionsErrorType.SystemPermissionDenied
      ) {
        // user denied permission
        setPermissionsError(PermissionErrorType.systemDenied)
      } else if (
        error.type === MediaPermissionsErrorType.UserPermissionDenied
      ) {
        // browser doesn't have access to devices
        setPermissionsError(PermissionErrorType.userDenied)
      } else if (
        error.type === MediaPermissionsErrorType.CouldNotStartVideoSource
      ) {
        // most likely when other apps or tabs are using the cam/mic (mostly windows)
        setPermissionsError(PermissionErrorType.trackError)
      }
    }
  }

  const renderAvatar = () => {
    if (avatar === 'custom') {
      return (
        <CustomAvatarConnect
          ref={heyGenRef}
          onAvatarPlayingFinished={onAvatarPlayingFinished}
          thereWasAnError={thereWasAnError}
          isRecording={false}
          // handleChunk={isTest ? undefined : handleChunk}
          handleVideoClick={handleVideoClick}
          onSessionStarted={onCustomAvatarSessionStarted}
          permissionsGranted={permissionsChecked}
        />
      )
    } else if (avatar === 'd-id') {
      return (
        <DIDConnect
          ref={heyGenRef}
          onAvatarPlayingFinished={onAvatarPlayingFinished}
          thereWasAnError={thereWasAnError}
          setThereWasAnError={setThereWasAnError}
          onSessionStarted={onHeyGenSessionStarted}
          isRecording={false}
          permissionsGranted={permissionsChecked}
          handleChunk={isTest ? undefined : handleChunk}
          setDuration={setDuration}
          handleVideoClick={handleVideoClick}
          onAvatarStartTalking={onAvatarStartTalking}
        />
      )
    } else if (azureKey) {
      return (
        <AzureConnect
          ref={heyGenRef}
          onAvatarPlayingFinished={onAvatarPlayingFinished}
          thereWasAnError={thereWasAnError}
          setThereWasAnError={setThereWasAnError}
          onSessionStarted={onHeyGenSessionStarted}
          isRecording={false}
          permissionsGranted={permissionsChecked}
          handleChunk={isTest ? undefined : handleChunk}
          setDuration={setDuration}
          handleVideoClick={handleVideoClick}
          azureKey={azureKey}
        />
      )
    } else if (heygenKey) {
      return (
        <HeyGenConnect
          ref={heyGenRef}
          onAvatarPlayingFinished={onAvatarPlayingFinished}
          thereWasAnError={thereWasAnError}
          setThereWasAnError={setThereWasAnError}
          onSessionStarted={onHeyGenSessionStarted}
          isRecording={false}
          permissionsGranted={permissionsChecked}
          handleChunk={isTest ? undefined : handleChunk}
          setDuration={setDuration}
          handleVideoClick={handleVideoClick}
          heygenKey={heygenKey}
          onAvatarStartTalking={onAvatarStartTalking}
          initialPhrase={initialPhrase}
          lang={lang}
          avatarId={avatarId}
          avatarQuality={avatarQuality}
        />
      )
    }
  }

  const renderControlPanel = () => {
    if (controlPanelVisible) {
      return (
        <ControlPanel
          ref={controlPanelRef}
          permissionsError={permissionsError}
          controls={controls}
          setControls={handleControlsChange}
          handleClose={handleClose}
          permissionsChecked={permissionsChecked}
          steps={controlPanelSteps}
          userName={userName}
          isRecording={true}
          orientation={orientation}
          devices={devices}
          selectedDeviceId={selectedDeviceId}
          setSelectedDeviceId={handleCameraChange}
          availableLanguages={availableLanguages}
          lang={lang}
          onChangeLang={onChangeLang}
          subtitlesOpenedByDefault={subtitlesOpenedByDefault}
        />
      )
    }
  }

  const renderScreeshotsProcessor = () => {
    if (!screenshotProcessed) {
      return (
        <CapturePhoto
          videoRef={cameraVideoRef}
          onComplete={() => {
            jsLogger.log('CapturePhoto, ON COMPLETE')
            setScreenshotProcessed(true)
            generateNewPhrase()
          }}
          interactionId={interactionId}
          sayLookAtCamera={() => {
            jsLogger.log('CapturePhoto, sayLookAtCamera')
            generateNewPhrase()
          }}
          cameraOn={cameraOn && avatarStarted}
        />
      )
    }
  }

  const onNotNowClick = async () => {
    setShowEmailInput(false)
    await dbSkipEmail(interactionId)
    generateNewPhrase()
  }

  const onConfirmClick = async (email: string) => {
    jsLogger.log('onConfirmClick', { email })
    setShowEmailInput(false)
    await dbSendUserEmail(interactionId, email)
    generateNewPhrase(email)
  }

  const renderEmailInput = () => {
    if (showEmailInput) {
      return (
        <EmailInput
          onNotNowClick={onNotNowClick}
          onConfirmClick={onConfirmClick}
          locale={lang}
        />
      )
    }
  }

  return (
    <PageContainer version={PACKAGE.version}>
      {renderScreeshotsProcessor()}
      {avatarEnabled && renderAvatar()}
      {isTest && (
        <RecorderDummy dgKey={transcriberKey} onPhrase={onUserPhrase} />
      )}
      {controlPanelVisible && !isTest && (
        <div
          className={`flex-1 bg-slate-100 rounded-md absolute top-[3rem] left-5 w-28 h-44`}
        >
          <Recorder
            ref={recorderRef}
            handleChunk={isTest ? undefined : handleChunk}
            onPhrase={onUserPhrase}
            isActive={true}
            onSpeech={onSpeech}
            cameraVideoRef={cameraVideoRef}
            controls={controls}
            setControls={handleControlsChange}
            setThereWasAnError={setThereWasAnError}
            permissionsError={permissionsError}
            streamRef={streamRef}
            interactionId={interactionId}
            transcriber={transcriber}
            transcriberKey={transcriberKey}
            setDevices={setDevices}
            setSelectedDeviceId={setSelectedDeviceId}
            onStartTalking={() => (userIsTalkingRef.current = true)}
            onCameraOn={onCameraOn}
            lang={lang}
          />
        </div>
      )}

      {permissionsError === PermissionErrorType.userDenied && <FixPermission />}
      {renderControlPanel()}
      {!controlPanelVisible && initVersion === 0 && (
        <div className='absolute top-0 left-0 bottom-0 right-0 w-full h-full flex items-center justify-center'>
          <div className='z-10 absolute top-20 left-4 text-gray-300'>
            <div>
              <div style={{ height: '50px', width: '50px' }}>
                <DotLottieReact
                  src='https://lottie.host/1ba91606-15d0-4f27-9f17-095c58dfb5ac/IivUjwcaOY.json'
                  autoplay
                  loop
                  backgroundColor='transparent'
                  speed={1}
                  style={{ width: '100%', height: '100%' }}
                  mode='forward'
                />
              </div>
            </div>
            <span className='text-[10px] pt-1'>
              <i>{getString('conversation_volume_up', lang)}</i>
            </span>
          </div>
          {checkingPermissions && (
            <div className='z-10 text-[24px] text-white font-semibold flex flex-col items-center'>
              <span className='drop-shadow-[1px_1px_1px_rgba(0,0,0,1)]'>
                <i>{getString('conversation_initializing', lang)}</i>
              </span>
              <div className='h-1 w-[187px] bg-white mt-[23px]'>
                <div className='h-1 w-[106px] bg-teal-600' />
              </div>
            </div>
          )}

          {!checkingPermissions && (
            <div className='z-10 w-[300px] flex flex-col items-center'>
              <span className='font-semibold text-[24px] text-white drop-shadow-[1px_1px_1px_rgba(0,0,0,1)] text-center leading-8'>
                {getString('conversation_about_to_chat', lang)}
              </span>
              <div className='w-full bg-white mt-10 rounded-xl flex flex-col items-center pt-7 pb-4'>
                <span className='text-[20px] font-semibold text-center leading-7 px-4'>
                  {permisssionsGranted
                    ? "Let's verify you"
                    : getString('conversation_request_permissions', lang)}
                </span>
                {renderButton()}
              </div>
            </div>
          )}
        </div>
      )}
      {renderEmailInput()}
    </PageContainer>
  )
}

export default Conversation
