import { FC } from 'react'

import { ReactComponent as IconLogo } from 'assets/FaceSignAILogo.svg'
import { getString } from 'controllers/localization'

type Props = {
  locale: string
}

const SessionTimedout: FC<Props> = ({ locale }) => {
  const heading = (
    <div className='flex flex-row w-full landscape:px-24 portrait:pl-2 portrait:pr-4 items-center justify-between py-6 w-full'>
      <a href='https://facesign.ai/'>
        <IconLogo width='138' height='32' />
      </a>
      <div>
        <a
          className='text-[#0099FF] portrait:text-sm landscape:text-lg font-medium'
          href='https://facesign.ai/'
        >
          {getString('send_email_learn_more', locale)}
        </a>
      </div>
    </div>
  )

  const subText = (
    <div className='landscape:mt-24 portrait:mt-16 flex flex-col portrait:px-12'>
      <p className='text-blackAlpha-500 text-sm text-center'>
        {getString('session_is_timed_out', locale)}
      </p>
    </div>
  )

  return (
    <div className='h-full w-full'>
      <div className='flex w-full h-full flex-col flex-1 items-center'>
        {heading}
        <hr className='border-gray-100 w-full' />
        {subText}
      </div>
    </div>
  )
}

export default SessionTimedout
